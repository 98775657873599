import { Navigate } from "react-router-dom";
import { AdminLayout } from "../pages/dashboard/layout/AdminLayout";
import { LazyRoute } from "../utils/helpers";
import { IModuleRouter } from "./index";

export const AdminDashRouter: IModuleRouter = {
  key: "dashboard",
  guard: (loggedIn) => loggedIn,
  layout: AdminLayout,
  routes: [
    {
      path: "/",
      element: <Navigate to="/dashboard" />,
    },
    LazyRoute(
      {
        path: "/dashboard",
      },
      () => import("../pages/dashboard/Dashboard")
    ),
    LazyRoute(
      {
        path: "/profile",
      },
      () => import("../pages/dashboard/Profile")
    ),
    LazyRoute(
      {
        path: "/customer",
      },
      () => import("../pages/dashboard/Customer")
    ),
    LazyRoute(
      {
        path: "/client",
      },
      () => import("../pages/dashboard/Client")
    ),
    LazyRoute(
      {
        path: "/client/create-new",
      },
      () => import("../pages/dashboard/CreateClient")
    ),
    LazyRoute(
      {
        path: "/client/create-new-whitelabel",
      },
      () => import("../pages/dashboard/CreateWhiteLabel")
    ),
    LazyRoute(
      {
        path: "/customer/:id/profile",
      },
      () => import("../pages/dashboard/CustormerProfile")
    ),
    LazyRoute(
      {
        path: "/merchant",
      },
      () => import("../pages/dashboard/Merchant")
    ),
     LazyRoute(
      {
        path: "/merchant/create-new",
      },
      () => import("../pages/dashboard/CreateGuestMerchant")
    ),
    LazyRoute(
      {
        path: "/merchant/:id/profile",
      },
      () => import("../pages/dashboard/MerchantProfile")
    ),
    LazyRoute(
      {
        path: "/logistics",
      },
      () => import("../pages/dashboard/Logistics")
    ),
    LazyRoute(
      {
        path: "/order",
      },
      () => import("../pages/dashboard/Order")
    ),
    LazyRoute(
      {
        path: "/order/summary/:id",
      },
      () => import("../pages/dashboard/OrderSummary")
    ),
    LazyRoute(
      {
          path: "/products"
      },
      () => import("../pages/dashboard/Products")
    ),
    LazyRoute(
      {
        path: "/products/summary/:id",
      },
      () => import("../pages/dashboard/ProductDetails")
    ),
    LazyRoute(
      {
        path: "/escrow",
      },
      () => import("../pages/dashboard/Escrow")
    ),
    LazyRoute(
      {
        path: "/escrow/summary/:id/",
      },
      () => import("../pages/dashboard/EscrowDetails")
    ),
    LazyRoute(
      {
        path: "/bill-transactions",

      },
      () => import("../pages/dashboard/Transactions")
    ),
    LazyRoute(
      {
        path: "/bill-transactions/summary/:id",

      },
      () => import("../pages/dashboard/TransactionSummary")
    ),
    LazyRoute(
      {
        path:"/helpdesk"
      },
      () => import("../pages/dashboard/Helpdesk")
    ),
    {
      path: "*",
      element: <div>Not found</div>,
    },
  ],
};
