const API_HOST = process.env.REACT_APP_API_PUBLIC_URL

export const Config = {
  apiUrl: `${API_HOST}/v1`,
  

  // defaults: {
  //   paginator: {
  //     pageKey: "page",
  //     limitKey: "limit",
  //     searchKey: "search",
  //     limit: 10,
  //     startPage: 1,
  //   },
  // },
};
